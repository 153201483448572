import { useDispatch, useSelector } from "react-redux";
import PartnerList from "./PartnerList";
import { endDateChange, startDateChange } from "../../Redux/Actions/PartnerActions";

const PartnerListMain = () => {

    const dispatch = useDispatch();
    const { startDate, endDate } = useSelector((state) => state.partnerList);

    return (
        <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Change Role</h2>
            </div>

            <div className="card mb-4 shadow-sm">
                <header className="card-header bg-white">
                    <div className="row gx-3 py-3">
                        <div className="col-lg-4 col-md-6 me-auto">
                            <input
                                type="text"
                                placeholder="Search..."
                                className="form-control p-2"
                            />
                        </div>
                        <div className="col-lg-2 col-6 col-md-3">
                            <input
                                type="date"
                                className="form-control"
                                value={startDate}
                                onChange={(e) => dispatch(startDateChange(e.target.value))}
                                placeholder="Start Date"
                            />
                        </div>
                        <div className="col-lg-2 col-6 col-md-3">
                            <input
                                type="date"
                                className="form-control"
                                value={endDate}
                                onChange={(e) => dispatch(endDateChange(e.target.value))}
                                placeholder="End Date"
                            />
                        </div>
                    </div>
                </header>
                <div className="card-body">
                    <div className="table-responsive">
                        <PartnerList />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerListMain;