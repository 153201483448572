import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";

export const listPartners = ({startDate, endDate}={}) => async (dispatch) => {
    const userInfoFromLocal = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null;

    const params = startDate && endDate ? { start_date: startDate, end_date: endDate } : {};

    try {
        const { data } = await axios.get(`${BASE_URL}/api/partner/getall`,
            {
                headers: {
                    'x-access-token': userInfoFromLocal.token
                },
                params
            }
        );

        dispatch({ type: 'GET_ALL_PARTNERS', payload: data.message })
    } catch (err) {
        dispatch({ type: 'GET_ALL_PARTNERS_FAIL', payload: err })
    }
}

export const deletePartner = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'DELETE_PARTNER', payload: id })

        const config = {
            headers: {
                'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
            },
        };

        const { data } = await axios.delete(`${BASE_URL}/api/partner/delete/${id}`, config);
        dispatch({ type: 'GET_ALL_PARTNERS', payload: data.message })
    } catch (err) {
        throw new Error(err)
    }
}

export const PayTopartner=(id,cash)=>async(dispatch)=>{
    try {
        if (!id || cash <= 0 || typeof(cash)!=='number') {
            throw new Error("ödəniş baş tutmadı")
        }
        dispatch({ type: 'PARTNER_PAY_REQUEST'});
        // const config = {
        //     headers: {
        //         'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
        //     },
        // };
        // const { data } = await axios.post(`${BASE_URL}/api/partner/pay`,{cash,partner_id:id},config);
        dispatch({ type: 'PARTNER_PAY_SUCCESS',payload:"Qebul olundu" });
    } catch (error) {
        const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
          dispatch({
            type: 'PARTNER_PAY_FAIL',
            payload: message,
          });
    }
}

export const getAllPayment=(page,id)=>async(dispatch)=>{
try {
    const config = {
        headers: {
            'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
        },
    };
    dispatch({ type: 'GET_ALL_PAYMENTS'})
    const { data } = await axios.get(`${BASE_URL}/api/partner/getallpayments?page=${page}&&partner=${id}`,config);
    dispatch({ type: 'GET_ALL_PAYMENTS_SUCCESS', payload: data.data,sum:data.sum,total:data.total })
} catch (error) {
    const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
    dispatch({ type: 'GET_ALL_PAYMENTS_ERROR', payload: message })
    
}
}

export const startDateChange = (date) => async (dispatch) => {
    dispatch({ type: 'START_DATE_CHANGE', payload: date })
}

export const endDateChange = (date) => async (dispatch) => {
    dispatch({ type: 'END_DATE_CHANGE', payload: date })
}
