import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PRODUCT_CREATE_RESET } from "../../Redux/Constants/ProductConstants";
import {
  createProduct,
} from "../../Redux/Actions/ProductActions";
import Toast from "../LoadingError/Toast";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import { listLanguage } from "../../Redux/Actions/LanguageActions";
import { listCategories } from "../../Redux/Actions/CategoryActions";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Select from "react-select";
import { listCatalogs } from "../../Redux/Actions/CatalogActions";
import { listPlatforms } from "../../Redux/Actions/PlatformActions";
import { listActors } from "../../Redux/Actions/ActorActions";
import { listDirectors } from "../../Redux/Actions/DirectorActions";
import { contentTypeList } from "../../Redux/Actions/ContentTypeActions";
import slugify from 'slugify';
import { listPartners } from "../../Redux/Actions/PartnerActions";
import { useLocation } from "react-router-dom";


const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};
const AddMovieMain = (props) => {
  const dispatch = useDispatch();
  const [movieInfo, setmovieInfo] = useState([
    {
      lang_code: "",
      name: "",
      description: "",
    },
    {
      lang_code: "",
      name: "",
      description: "",
    },
    {
      lang_code: "",
      name: "",
      description: "",
    },
    {
      lang_code: "",
      name: "",
      description: "",
    },
  ]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [directorIds, setDirectorIds] = useState([]);
  const [actorIds, setActorIds] = useState([]);
  const [featured, setFeatured] = useState(false);
  const [isSlider, setIsSlider] = useState(false);
  const [trailers, setTrailers] = useState([]);
  const [trailerName, setTrailerName] = useState("");
  const [trailerUrl, setTrailerUrl] = useState("");
  const [imdb, setImdb] = useState("");
  const [catalogIds, setCatalogIds] = useState([]);
  const [platformId, setPlatformId] = useState(null);
  const [mainClaim, setMainClaim] = useState("");
  const [age, setAge] = useState(18);
  const [price, setPrice] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [time, setTime] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [previewBackground, setPreviewBackground] = useState("");
  const [mainImg, setMainImg] = useState("");
  const [mainBack, setMainBack] = useState("");
  const [contentTypeId, setContentTypeId] = useState("");
  const [slugName, setSlugName] = useState("");
  const [isPartner, setIsPartner] = useState(false)
  const [hide, setHide] = useState(false)
  const [backupUrls, setBackupUrls] = useState([]);
  const [hasDrm, setHasDrm] = useState(false)
  const [drmLicenseKey, setDrmLicenseKey] = useState('')
  const [releaseDate, setReleaseDate] = useState('');
  const [announcment, setAnnouncment] = useState('')
  const [heightRatio, setHeightRatio] = useState(9)
  const [widthRatio, setWidthRatio] = useState(16)
  const [objectFitType, setObjectFitType] = useState('cover')


  const [partnerId, setPartnerId] = useState('')

  const productCreate = useSelector((state) => state.productCreate);
  const categorInfo = useSelector((state) => state.categoryList);
  const catalogInfo = useSelector((state) => state.catalogList);
  const platformInfo = useSelector((state) => state.platformList);
  const actorInfo = useSelector((state) => state.actorList);
  const directorInfo = useSelector((state) => state.directorList);
  const languageInfo = useSelector((state) => state.languageList);
  const contentTypeInfo = useSelector((state) => state.contentType);
  const [partnerType, setPartnerType] = useState('');

  const [percent, setPercent] = useState(0);
  const [priceForView, setPriceForView] = useState(0);

  const { contentType } = contentTypeInfo ?? [];
  const { languages } = languageInfo ?? [];
  const { categories } = categorInfo;
  const { actors } = actorInfo;
  const { directors } = directorInfo;

  const { catalogs } = catalogInfo;
  const { platforms } = platformInfo;
  const { loading, error, product } = productCreate;

  const location = useLocation()
  const isInteractive = new URLSearchParams(location.search).get('interactive') === 'true'

  useEffect(() => {
    if (product) {
      toast.success("Movie Added", ToastObjects);
      dispatch({ type: PRODUCT_CREATE_RESET });
      setCategoryIds([]);
      setActorIds([]);
      setDirectorIds([]);
      setmovieInfo([]);
      setCategoryIds([]);
      setTrailerName("");
      setTrailerUrl("");
      setTrailers([]);
      setAge(0);
      setImdb("");
      setFeatured("");
      setVideoUrl("");
      setPrice(0);
      setBackupUrls([]);
      setHeightRatio(9)
      setWidthRatio(16)
      setHasDrm(false)
      setDrmLicenseKey('')
      setAnnouncment('')
      setReleaseDate('')
      setObjectFitType('cover')
    }
  }, [product, dispatch]);

  useEffect(() => {
    dispatch(listCategories({ lang: "AZ", page: 1 }));
    dispatch(listActors({ page: 1 }));
    dispatch(listDirectors({ page: 1 }));
    dispatch(listCatalogs({ lang: "AZ", page: 1 }));
    dispatch(listLanguage());
    dispatch(listPlatforms());
    dispatch(contentTypeList("AZ"));
    dispatch(listPartners())

  }, [dispatch]);

  const partnerTypeHandler = React.useCallback((e) => {
    setPartnerType(e.target.value)
    setPercent(0);
    setPriceForView(0);
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();


    dispatch(
      createProduct({
        price,
        categories: categoryIds?.map(c => c.id),
        my_lang: movieInfo,
        platform: platformId,
        type: contentTypeId.slug,
        catalogs: catalogIds?.map(c => c.id),
        age,
        slug: slugName,
        claims: mainClaim,
        is_featured: featured,
        is_slider: isSlider,
        imdb,
        interactive: isInteractive,
        trailers,
        actors: actorIds?.map(c => c.id),
        directors: directorIds,
        url: videoUrl,
        time,
        main_picture: "",
        slider_image: "",
        content_type: contentTypeId.id,
        hide,
        partner_type : partnerType,
        partner_id: partnerId,
        cash: priceForView,
        backup_urls: backupUrls,
        percent,
        release_date: releaseDate,
        drm: hasDrm,
        drm_license_key: drmLicenseKey,
        announcment,
        height_ratio: heightRatio,
        width_ratio: widthRatio,
        object_fit_type: objectFitType,
      },
        { mainImg, mainBack }
      )
    );
    
  };
  const handleImageUpload = (file) => {
    setMainImg(file);
    previewFile(file, setPreviewImage);
  };
  const previewFile = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      state(reader.result);
    };
  };
  const handleBackgroundUpload = (file) => {
    setMainBack(file);
    previewFile(file, setPreviewBackground);
  };


  const handleChangePrice = (price) => {
    const priceNumber = parseFloat(Number(price))
    if (priceNumber) {
      setMainClaim("");
      setPrice(parseFloat(priceNumber));
    } else if (price==='') {
      setPrice('')
    }
  };

  const handleChangeCategory = (option) => {
    setCategoryIds(option);
  };
  const handleGetCategory = (value) => {
    dispatch(listCategories({ lang: "AZ", page: 1, q: value }));
  }
  const handleGetCatalog = (value) => {
    dispatch(listCatalogs({ lang: "AZ", page: 1, q: value }));

  }
  const handleChangeCatalogs = (option) => {
    setCatalogIds(option)
  };

  const handleChangeActors = (option) => {
    setActorIds(option)
  };

  const handleGetActorName = (option) => {
    dispatch(listActors({ page: 1, q: option }));
  };


  const handleChangeDirectors = (option) => {
    setDirectorIds([]);
    option.map((c) => setDirectorIds((prev) => [...prev, c.id]));
  };

  const handleBackupUrls = (e, index) => {
    setBackupUrls((prev) => {
      const newState = [...prev];
      newState[index] = e.target.value;
      return newState;
    });
  };

  const handleAddBackupUrl = () => {
    setBackupUrls((prev) => [...prev, ""]);
  };

  const handleBackupUrlDelete = (index) => {
    setBackupUrls((prev) => prev.filter((_, i) => i !== index));
  };


  const handleChangeLanguage = (e, i, l) => {
    const { name, value } = e.target;
    const newState = [...movieInfo];
    newState[i] = {
      ...newState[i],
      lang_code: l,
      [name]: value,
    };

    setmovieInfo(newState);
    if (l.toLowerCase() === "az" && value !== "" && name === "name") {
      setSlugName(slugify(value.toLowerCase()))
    }
  };

  const handleRemoveTrailer = (e) => {
    const ind = Number(e.target.getAttribute("trailer-index"));
    setTrailers((ts) => ts.filter((_, i) => i !== ind));
  };
  const handleAddTrailer = (e, name, url) => {
    e.preventDefault();
    setTrailers((state) => [...state, { name, url }]);
    setTrailerName("");
    setTrailerUrl("");
  };

  const { partners } = useSelector(state => state.partnerList)


  useEffect(() => {
  }, [partners])

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/products" className="btn btn-danger text-white">
              Go to movies
            </Link>
            <h2 className="content-title">Add Movie</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}
                  {contentType && (
                    <div className="mb-4">
                      <label className="form-label">Types</label>
                      <select
                        defaultValue={"DEFAULT"}
                        required
                        className="form-control"
                        onChange={(e) => setContentTypeId({ id: e.target.value.split("$")[0], slug: e.target.value.split("$")[1] })}
                      >
                        <option value="DEFAULT" disabled>
                          Type ...
                        </option>
                        {contentType &&
                          contentType.map((p) => (
                            <option value={`${p.id}$${p.slug}`} key={p.id}>
                              {p.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                  <div className="mb-4">
                    <label className="form-label">Platforms</label>
                    <select
                      defaultValue={"DEFAULT"}
                      className="form-control"
                      onChange={(e) => setPlatformId(e.target.value)}
                    >
                      <option value="DEFAULT" disabled>
                        Platform ...
                      </option>
                      {platforms &&
                        platforms.map((p) => (
                          <option value={p.id} key={p.id}>
                            {p.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <Tabs>
                    {languages && movieInfo.length > 0 &&
                      languages.map((l, index) => (
                        <Tab
                          eventKey={l.name}
                          title={l.name}
                          key={`movie-tab-${l.id}`}
                        >
                          <div className="mb-4">
                            <label
                              htmlFor={`product_title-${l.id}`}
                              className="form-label"
                            >
                              Movie title {l.name}
                            </label>
                            <input
                              type="text"
                              placeholder="Type here"
                              className="form-control"
                              id={`product_title-${l.id}`}
                              name="name"
                              required
                              value={movieInfo[index].name}
                              onChange={(e) =>
                                handleChangeLanguage(e, index, l.name)
                              }
                            />
                          </div>

                          <div className="mb-4">
                            <label className="form-label">Description</label>
                            <textarea
                              id={`movie_desc-${l.id}`}
                              placeholder="Type here"
                              className="form-control"
                              name={`description`}
                              rows="7"
                              value={movieInfo[index].description}
                              onChange={(e) =>
                                handleChangeLanguage(e, index, l.name)
                              }
                            ></textarea>
                          </div>
                        </Tab>
                      ))}
                  </Tabs>
                  <div className="mb-4">
                    <label htmlFor="slug">Slug()</label>
                    <input className="form-control" placeholder="slug" id="slug" name="slug"
                      value={slugName}
                      required
                      onChange={e => setSlugName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="categories" className="form-label">
                      Categories
                    </label>
                    {categories && categories.data && (
                      <Select
                        onChange={handleChangeCategory}
                        onInputChange={handleGetCategory}
                        isMulti={true}
                        options={categories.data}
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                      />
                    )}
                  </div>

                  <div className="mb-4">
                    <label htmlFor="catalogs" className="form-label">
                      Catalogs
                    </label>
                    {catalogs && (
                      <Select
                        onChange={handleChangeCatalogs}
                        onInputChange={handleGetCatalog}
                        isMulti={true}
                        options={catalogs.data}
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                      />
                    )}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="product_price"
                      min={0}
                      value={price}
                      onChange={(e) => handleChangePrice(e.target.value)}
                    />
                  </div>
                  {(price === 0 || typeof price === "undefined") ? (
                    <div className="mb-4">
                      <label htmlFor="plan" className="form-label">
                        Plan
                      </label>
                      <select className="form-control" defaultValue={""} required onChange={e => setMainClaim(e.target.value)}>
                        <option value={""}>-</option>
                        <option value="Free">Free</option>
                        <option value="Subscriber">Subscriber</option>
                        <option value="UnSubscriber">UnSubscriber</option>
                        <option value="Pro">Pro</option>
                      </select>
                    </div>
                  ) : null}

                  <div className="mb-4">
                    <label htmlFor="movie_age" className="form-label">
                      Age
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="movie_age"
                      required
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="movie_imdb" className="form-label">
                      IMDB
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="movie_imdb"
                      value={imdb}
                      onChange={(e) => setImdb(e.target.value)}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="movie_url" className="form-label">
                      Video Url
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="movie_url"
                      value={videoUrl}
                      onChange={(e) => setVideoUrl(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <div className="mb-2">Backup URLs</div>
                    {backupUrls.map((url, index) => {
                      return (
                        <div key={index} className="mb-3 d-flex gap-2">
                          <input
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            id="movie_url"
                            value={backupUrls[index]}
                            onChange={(e) => handleBackupUrls(e, index)}
                          />
                          <button type="button" className="btn btn-danger" onClick={() => handleBackupUrlDelete(index)}><i className="fas fa-trash-alt"></i></button>
                        </div>
                      )
                    })}
                  </div>
                  <div className="mb-4 d-flex justify-content-end">
                    <button type="button" className="btn btn-dark" onClick={handleAddBackupUrl}>Add URL</button>
                  </div>
                  <div className="mb-4 d-flex gap-3">
                    <div className="flex-grow-1">
                      <label htmlFor="width_ratio" className="form-label">
                        Width Ratio
                      </label>
                      <input
                        type="number"
                        placeholder="16"
                        className="form-control"
                        id="width_ratio"
                        value={widthRatio}
                        onChange={(e) => setWidthRatio(Number(e.target.value))}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <label htmlFor="height_ratio" className="form-label">
                        Height Ratio
                      </label>
                      <input
                        type="number"
                        placeholder="9"
                        className="form-control"
                        id="height_ratio"
                        value={heightRatio}
                        onChange={(e) => setHeightRatio(Number(e.target.value))}
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="object_fit" className="form-label">
                      Object Fit
                    </label>
                    <select
                      className="form-control"
                      id="object_fit"
                      value={objectFitType}
                      onChange={(e) => setObjectFitType(e.target.value)}
                    >
                      <option value="cover">Cover</option>
                      <option value="contain">Contain</option>
                      <option value="fill">Fill</option>
                      <option value="none">None</option>
                      <option value="scale-down">Scale Down</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="movie_url" className="form-label">
                      Time
                    </label>
                    <input
                      type="string"
                      placeholder="2:00"
                      className="form-control"
                      id="movie_time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="actors" className="form-label">
                      Actors
                    </label>
                    {actors && (
                      <Select
                        onChange={handleChangeActors}
                        onInputChange={handleGetActorName}
                        isMulti={true}
                        id="actors"
                        options={actors.data}
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                      />
                    )}
                  </div>

                  <div className="mb-4">
                    <label htmlFor="directors" className="form-label">
                      Directors
                    </label>
                    {directors && (
                      <Select
                        onChange={handleChangeDirectors}
                        isMulti={true}
                        id="directors"
                        options={directors.data}
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                      />
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Images</label>

                    <input
                      className="form-control mt-3"
                      type="file"
                      onChange={(e) => handleImageUpload(e.target.files[0])}
                    />
                    {previewImage && (
                      <img width={150} src={previewImage} alt="" />
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Background Images</label>
                    <input
                      className="form-control mt-3"
                      type="file"
                      onChange={(e) =>
                        handleBackgroundUpload(e.target.files[0])
                      }
                    />
                    {previewBackground && (
                      <img width={150} src={previewBackground} alt="" />
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="movie_featured"
                      className="form-check-label me-2"
                    >
                      Gizlədilsin? (Saytda görünməyəcək)
                    </label>
                    <input
                      type="checkbox"
                      placeholder="Type here"
                      className="form-check-input"
                      id="movie_featured"
                      value={featured}
                      onChange={(e) => setHide(e.target.checked)}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="movie_featured"
                      className="form-check-label me-2"
                    >
                      Önə çıxarılsın?
                    </label>
                    <input
                      type="checkbox"
                      placeholder="Type here"
                      className="form-check-input"
                      id="movie_featured"
                      value={featured}
                      onChange={(e) => setFeatured(e.target.checked)}
                    />
                  </div>


                  <div className="mb-4">
                    <label
                      htmlFor="movie_slider"
                      className="form-check-label me-2"
                    >
                      Slider hissəsində göstərilsin?
                    </label>
                    <input
                      type="checkbox"
                      placeholder="Type here"
                      className="form-check-input"
                      id="movie_slider"
                      value={isSlider}
                      onChange={(e) => setIsSlider(e.target.checked)}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="has_drm" className="form-label me-2">
                      DRM
                    </label>
                    <input type="checkbox" className="form-check-input" id="has_drm" value={hasDrm} checked={hasDrm} onChange={(e) => setHasDrm(e.target.checked)} />
                  </div>
                  {hasDrm && (
                    <div className="mb-4">
                      <label htmlFor="drm_license_key" className="form-label">
                        DRM License Key
                      </label>
                      <input 
                        type="text" 
                        className="form-control" 
                        id="drm_license_key" 
                        value={drmLicenseKey} 
                        onChange={(e) => setDrmLicenseKey(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="mb-4">
                    <label htmlFor="announcment" className="form-label">
                      Announcment
                    </label>
                    <input type="text" className="form-control" id="announcment" value={announcment} onChange={(e) => setAnnouncment(e.target.value)} />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="release_date" className="form-label">
                      Release Date
                    </label>
                    <input 
                      type="text" 
                      className="form-control" 
                      id="release_date" 
                      placeholder={new Date().getFullYear()}
                      value={releaseDate} 
                      onChange={(e) => setReleaseDate(e.target.value)} 
                    />
                  </div>


                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="mb-4 ">
                      <label htmlFor={`movie_trailer`} className="form-label">
                        Trailer Name
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        className="form-control"
                        id={`movie_trailer`}
                        name="movie_trailer"
                        value={trailerName}
                        onChange={(e) => setTrailerName(e.target.value)}
                      />
                    </div>

                    <div className="mb-4 ms-2 flex-grow-1">
                      <label
                        htmlFor={`movie_trailer_url`}
                        className="form-label"
                      >
                        Trailer Url
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        className="form-control"
                        id={`movie_trailer_url`}
                        name="movie_trailer_url"
                        value={trailerUrl}
                        onChange={(e) => setTrailerUrl(e.target.value)}
                      />
                    </div>
                    <div className="ms-5 mt-2">
                      <button
                        className="btn btn-dark"
                        onClick={(e) =>
                          handleAddTrailer(e, trailerName, trailerUrl)
                        }
                      >
                        Add Trailer
                      </button>
                    </div>
                  </div>




                  <div className="mb-4">
                    <ul className=" w-100 p-0">
                      {trailers.map((t, id) => (
                        <li
                          style={{ border: "1px solid black" }}
                          key={id}
                          className="list-group-item align-items-center justify-content-between d-flex"
                        >
                          trailer: {t.name} &nbsp;&nbsp;|&nbsp;&nbsp; url:
                          {t.url}
                          <div
                            trailer-index={id}
                            className="p-2 text-danger"
                            onClick={handleRemoveTrailer}
                          >
                            <i
                              style={{ pointerEvents: "none" }}
                              className="fas fa-times"
                            ></i>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="add_partner"
                      className="form-check-label me-2"
                    >
                      Partnyor əlavə olunsun?
                    </label>
                    <input
                      type="checkbox"
                      placeholder="Type here"
                      className="form-check-input"
                      id="add_partner"
                      value={isPartner}
                      onChange={(e) => setIsPartner(e.target.checked)}
                    />

                  </div>

                  <div className={`${isPartner ? 'd-block' : 'd-none'}`}>
                    <div className="mb-4">
                      <label>Partner Type</label>
                      <select onInput={(e)=>{partnerTypeHandler(e)}} className="form-control">
                      <option value={null}>Seçilmədi</option>
                        <option
                          value={'price'}
                          key={'price'}
                          >Faizlə
                        </option>
                        <option
                          value={'subscriber'}
                          key={'subscriber'}
                          >Baxış sayına görə
                        </option>
                      </select>
                    </div>
                        
                    {partnerType==="price" ? <><div className="mb-4">
                      <label className="form-label">
                        Partners
                      </label>

                      <select onInput={e => setPartnerId(e.target.value)} id="directors" className="form-control">
                        <option value={null}>Seçilmədi</option>
                        {partners && partners.map(({ _id, firstName, lastName }) => (
                          <option
                            value={_id}
                            key={_id}>
                            {firstName} {lastName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="form-label">
                        Faiz payı
                      </label>
                      <input
                        type="number"
                        step={0.01}
                        min={0}
                        placeholder="14"
                        className="form-control"
                        id="percent"
                        value={percent}
                        onChange={(e) => setPercent(e.target.value)}
                      />
                    </div></> : partnerType==="subscriber" ? <><div className="mb-4">
                      <label className="form-label">
                        Partners
                      </label>

                      <select onInput={e => setPartnerId(e.target.value)} id="directors" className="form-control">
                        <option value={null}>Seçilmədi</option>
                        {partners && partners.map(({ _id, firstName, lastName }) => (
                          <option
                            value={_id}
                            key={_id}>
                            {firstName} {lastName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="form-label d-block">
                          Hər baxışa
                      </label>
                      <input
                        type="number"
                        step={0.01}
                        min={0}
                        placeholder="0"
                        className="form-control"
                        id="viewCount"
                        value= {priceForView}
                        onChange={(e) => setPriceForView(e.target.value)}
                      />
                    </div></>: null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddMovieMain;
