import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listUser } from "../../Redux/Actions/userActions";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../api/baseConfig";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import ReactPaginate from "react-paginate";
import classes from './User.module.css'
import axios from "axios";
import { useCallback } from "react";

const UserComponent = () => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const [q, setQ] = useState("")
  const [paymentCode, setPaymentCode] = useState("")
  const { loading, error, users } = userList;
  const [allUsers, setAllUsers] = useState(userList?.users);
  const [page, setPage] = useState(1);
  const [subscriberCount, setSubscriberCount] = useState(0)
  const [asubscriberCount, setASubscriberCount] = useState(0)
  const [isFiltered, setIsFiltered] = useState(false)
  const [activeSubs, setActiveSubs] = useState(false)
  const [passiveSubs, setPassiveSubs] = useState(false)
  const [allSubs, SetAllSubs] = useState(false)

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  useEffect(() => {
    dispatch(listUser({ page, q, paymentCode }));
  }, [dispatch, page, q, paymentCode]);


  const [disabledId, setDisabledId] = useState('')
  const onPartner = async (user) => {
    setDisabledId(user.id)
    if (window.confirm('Are you sure?')) {
      await fetch(`${BASE_URL}/api/partner/add`, {
        method: 'POST',
        body: JSON.stringify({
          user_id: user.id,
          partner_name: user.username
        }),
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
        }
      }).then(res => res.json())
        .then(data => alert(data.message))

    }
  }

  




  const loadContent = useCallback((page) => {
    setAllUsers(users)
    axios.get(`${BASE_URL}/api/user/getalluser?claims=Subscriber&page=`+page, {
      headers: {
        'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
      }
    }).then(res => {
      setSubscriberCount(res.data.total)
    })

    axios.get(`${BASE_URL}/api/user/getalluser?claims=Subscriber&status=active&page=`+page, {
      headers: {
        'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
      }
    }).then(res => {
      setASubscriberCount(res.data.total)
    })


  }, [users])
  
  const ASubscriberFilter = useCallback((page) => {
    axios.get(`${BASE_URL}/api/user/getalluser?claims=Subscriber&status=active&page=`+page, {
      headers: {
        'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
      }
    }).then(res => {
      setAllUsers(res.data);
    })
  }, [])

  const PSubscriberFilter = useCallback((page) => {
    axios.get(`${BASE_URL}/api/user/getalluser?claims=Subscriber&status=passive&page=`+page, {
      headers: {
        'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
      }
    }).then(res => {
      setAllUsers(res.data);
    })
  }, [])

  const SubscriberFilter = useCallback((page) => {
    axios.get(`${BASE_URL}/api/user/getalluser?claims=Subscriber&page=`+page, {
      headers: {
        'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
      }
    }).then(res => {
      setAllUsers(res.data);
    })
  }, [])


  useEffect(() => {
    if(!isFiltered){
      loadContent(page)
    } else {
      if(activeSubs) ASubscriberFilter(page)
      else if(passiveSubs) PSubscriberFilter(page)
      else if(allSubs) SubscriberFilter(page)
    }

  }, [isFiltered, loadContent, page, activeSubs, passiveSubs, allSubs, ASubscriberFilter, PSubscriberFilter, SubscriberFilter])



  const ASubFilterBtn = useCallback(() => {
    setIsFiltered(true)
    setActiveSubs(true)
    setPassiveSubs(false)
    SetAllSubs(false)
  }, [])
  const PSubFilterBtn = useCallback(() => {
    setIsFiltered(true)
    setActiveSubs(false)
    setPassiveSubs(true)
    SetAllSubs(false)
  }, [])
  const AllSubFilterBtn = useCallback(() => {
    setIsFiltered(true)
    setActiveSubs(false)
    setPassiveSubs(false)
    SetAllSubs(true)
  }, [])

  const NoFilterBtn = useCallback(() => {
    setIsFiltered(false)
    setActiveSubs(false)
    setPassiveSubs(false)
    SetAllSubs(false)
  }, [])

  // const PSubscriberFilter = useCallback(() => {
  //   axios.get(`${BASE_URL}/api/user/getalluser?claims=Subscriber&status=passive&page=`+page, {
  //     headers: {
  //       'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
  //     }
  //   }).then(res => {
  //     setAllUsers(res.data);
  //   })
  // }, [page])

  // useEffect(() => {
  //   axios.get(`${BASE_URL}/api/user/getalluser?claims=Subscriber&status=active&page=`+page, {
  //     headers: {
  //       'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
  //     }
  //   }).then(res => {
  //     setASubscriberCount(res.data.total)
  //   })
  // }, [page, subscribers])
  // useEffect(() => {
  //   axios.get(`${BASE_URL}/api/user/getalluser?claims=Subscriber&status=passive&page=`+page, {
  //     headers: {
  //       'x-access-token': JSON.parse(localStorage.getItem('userInfo')).token
  //     }
  //   }).then(res => {
  //     setPSubscriberCount(res.data.total)
  //   })
  // }, [page, subscribers])

  // const AllUsersFilter = useCallback(() => {
  //     setAllUsers(users);
  // }, [users])
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Customers</h2>
        {/* <div>
          <Link to="#" className="btn btn-primary">
            <i className="material-icons md-plus"></i> Create new
          </Link>
        </div> */}
      </div>
      <div className="card mb-4">
        <header className="card-header">
          <div className="row gx-3">
            <div className="col-lg-4 col-md-6 me-auto">
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
                value={q}
                onChange={e => setQ(e.target.value.toLowerCase())}
              />
            </div>
            <div className="col-lg-4 col-md-6 me-auto">
              <input
                type="text"
                placeholder="Search By Payment Code..."
                className="form-control"
                value={paymentCode}
                onChange={e => setPaymentCode(e.target.value)}
              />
            </div>
            {/* <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Show 20</option>
                <option>Show 30</option>
                <option>Show 40</option>
                <option>Show all</option>
              </select>
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Status: all</option>
                <option>Active only</option>
                <option>Disabled</option>
              </select>
            </div> */}
          </div>
        </header>

        {/* Card */}

        <div className="card-body">
          <div className={classes.alldivs}>
            <div >
                <h5 className={classes.activePassive +" btn btn-dark btn-lg"} onClick={AllSubFilterBtn} >Subscribers: {subscriberCount}</h5>
                <h5 className={classes.activePassive +" btn btn-dark btn-lg"} onClick={ASubFilterBtn} >Active: {asubscriberCount}</h5>
                <h5 className={classes.activePassive +" btn btn-dark btn-lg"} onClick={PSubFilterBtn} >Passive: {subscriberCount - asubscriberCount}</h5>
            </div>
            <div className={classes.subUnsub +" btn-dark btn-lg"}>
                <h5>Not subscribed: {users?.total - subscriberCount}</h5>
            </div>
          </div>

          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <>
                  <button onClick={NoFilterBtn} style={{width:"100%",fontSize:"25px",marginBottom:"15px"}} className="btn btn-dark btn-lg">{users.total}</button>

<div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-3">

  {allUsers?.data?.map((user) => (
    <div className="col" key={user.id}>
      <div className="card card-user shadow-sm">
        <div className="card-header">
          <div className=" d-flex justify-content-end">
            <div className="d-flex justify-content-end">
              <button
                onClick={() => onPartner(user)}
                className="btn btn-warning"
                disabled={(user.id === disabledId) || user.claims.includes('partner')}
              >
                Parnter
              </button>
            </div>
          </div>
          <Link to={`/user/${user.email}`}>
          <img
            className="img-md img-avatar"
            src="images/favicon.png"
            alt="User pic"
          />
             </Link>
      
       
        </div>
        <div className="card-body">
          <h5 className="card-title mt-5">{user.firstName}</h5>
          <div className="card-text text-muted">
            {user.claims && user.claims.includes("Admin") ? (
              <p className="m-0">Admin</p>
            ) : (
              <p className="m-0">Customer</p>
            )}

            <p>
              <a href={`mailto:${user.email}`}>{user.email}</a>
              <hr />
              <span>Phone: <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a> </span>
            </p>
            <hr />
            <b>{user.claims.filter(c => c !== "Free")?.join(", ")}</b>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>
            
            </>
  
          )}

          {/* nav */}
          <div className="d-flex justify-content-end">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={allUsers?.total ? Math.ceil(allUsers.total / 20) : 1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserComponent;
